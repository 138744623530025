import * as React from "react"
import Layout from "../components/layout"
import {Container, Box, Heading, Text, Link, Flex, Kicker, SuperHeading, Subhead} from "../components/ui"
import ChevronRight from "../components/chevron-right"
import * as styles from "../components/about-hero.css"

export default function Contact() {
  return (
    <Layout title="Contact">
      <Box paddingY={4}>
        <Container>
          <SuperHeading className={styles.aboutHeroHeader} paddingY={0}>
            Contact Us
          </SuperHeading>

          <Subhead>
            True Life Audio Amplifiers can be purchased all over the world. Please contact us directly by e-mail or phone, so we can help you finding the right instrument, service, distributor or retailer.
          </Subhead>

          {/*<Box as="li" width="half" padding={3} paddingY={3}>*/}
            <Text paddingY={5}>
              <b>Address</b>: Ippokratous 13,Keratsini 18756, Athens, Greece<br/>
              <b>Phone</b>: +30 210 5757605 +30 694 8787510<br/>
              <b>Email</b>: info@truelifeaudio.com<br/>
            </Text>
          {/*</Box>*/}
        </Container>

        <Box paddingY={5}>
        <Container>
          <div className="p-0">
            <form name="tlacontact"
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="tlacontact" />
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label htmlFor="first-name">First name</label>
                  <input type="text" id="first-name" name="first-name" className="form-input px-3 py-4 rounded-md"
                         required/>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="last-name">Last name</label>
                  <input type="text" id="last-name" name="last-name" className="form-input px-3 py-4 rounded-md"
                         required/>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" name="email" className="form-input px-3 py-4 rounded-md" required/>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="phone">
                    <div className="flex align-items">
                      Phone
                      <span className="ml-auto opacity-75">Optional</span>
                    </div>
                  </label>
                  <input type="tel" id="phone" name="phone" className="form-input px-3 py-4 rounded-md"/>
                </div>
                <div className="flex flex-col col-span-2">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" id="subject" name="subject" className="form-input px-3 py-4 rounded-md" required/>
                </div>
                <div className="flex flex-col col-span-2">
                  <label htmlFor="subject">
                    <div className="flex align-items">
                      Message
                      <span className="ml-auto opacity-75">Max. 500 characters</span>
                    </div>
                  </label>
                  <textarea maxLength="500" rows="4" type="text" id="subject" name="subject"
                            className="form-input px-3 py-2 rounded-md" required/>
                </div>
              </div>
              <div className="flex justify-end py-4">
                <button type="submit"
                        className="bg-blue-900 text-white font-bold py-4 px-12 rounded focus:ring focus:ring-blue-700 hover:bg-blue-800">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Container>
        </Box>
      </Box>
    </Layout>
  )
}

